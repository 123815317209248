export const LocalStorageKey ={
  CustomerCode: 'CustomerCode',
  UserRole: 'UserRole',
  ApiKey: 'ApiKey',
  EmailSender: 'EmailSender',
  SmsSender: 'SmsSender',
  VoiceSender:'VoiceSender',
  RedirectWebsite:"RedirectWebsite"
}


