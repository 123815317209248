import { Injectable } from '@angular/core';
import { LocalStorageKey } from '../constants/user/local-storage-key';
import { IUserInformation } from '../interfaces/user/user-information';

@Injectable()
export class LocalStorageService {

  constructor() { }

  setUserInfo(userInformation: IUserInformation) {

    if (userInformation == null) {
      this.setWithExpiry(LocalStorageKey.UserRole, "customer", ttl);
      return;
    }

    var ttl = userInformation.sessionTtl > 0 ? userInformation.sessionTtl : 23;
    var userRole = "customer";

    if (userInformation.userRole != null) {
      userRole = userInformation.userRole.toLowerCase();

      if (userInformation.userRole == "SuperAdmin") {
        userRole = "admin";
      }
    }

    this.setWithExpiry(LocalStorageKey.CustomerCode, userInformation.customerCode, ttl);
    this.setWithExpiry(LocalStorageKey.UserRole, userRole, ttl);
    this.setWithExpiry(LocalStorageKey.ApiKey, userInformation.apiKey, ttl);
    this.setWithExpiry(LocalStorageKey.EmailSender, userInformation.emailSender, ttl);
    this.setWithExpiry(LocalStorageKey.SmsSender, userInformation.smsSender, ttl);
    this.setWithExpiry(LocalStorageKey.VoiceSender, userInformation.voiceSender, ttl);
  }

  getUserInfo(): IUserInformation {
    var userInfo: IUserInformation = {
      userRole: this.getWithExpiry(LocalStorageKey.UserRole),
      customerCode: this.getWithExpiry(LocalStorageKey.CustomerCode),
      apiKey: this.getWithExpiry(LocalStorageKey.ApiKey),
      emailSender: this.getWithExpiry(LocalStorageKey.EmailSender),
      smsSender: this.getWithExpiry(LocalStorageKey.SmsSender),
      voiceSender: this.getWithExpiry(LocalStorageKey.VoiceSender)
    };
    return userInfo;
  }

  setWithExpiry(key, value, ttl) {
    var now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + (ttl * 60 * 60 * 1000),
    }
    //console.log("expiry=", item.expiry);
    localStorage.setItem(key, JSON.stringify(item))

  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
}
