import { Component, OnInit, ViewChildren, ElementRef } from '@angular/core';

import * as algoliasearch from 'algoliasearch/lite';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';


const algoliaClient = algoliasearch(
  'GWPWUBFBP3',
  '15e2231fc820e6091145a46a34aba351'
);

@Component({
  selector: 'app-management-header',
  templateUrl: './management-header.component.html',
  styleUrls: ['./management-header.component.scss']
})
export class ManagementHeaderComponent implements OnInit {

  @ViewChildren('.ais-SearchBox-input') searchInputs;

  public clicked: boolean = false;
  public clickSbj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 

  constructor(private router: Router,
    private elementRef: ElementRef) {
    this.clickSbj.subscribe(x => this.clicked = x);
  }

  config = {
    indexName: 'Dillo_Docs',
    searchClient:{
      search(requests) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              processingTimeMS: 0,
            })),
          });
        }
        
        return algoliaClient.search(requests);
      },
    }
  };

  ngOnInit() {
    

  }

  ngAfterViewInit() {
    const source = fromEvent(this.elementRef.nativeElement.querySelector('.ais-SearchBox-input'), 'keyup')
    source.subscribe(x => {
      this.clickSbj.next(false);
    });
  }


  redirect(url: string) {
    this.clickSbj.next(true);
    this.router.navigate([url]);
  }

}
