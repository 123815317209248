import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ManagementLayoutComponent } from './layout/management/management-layout/management-layout.component';


const routes: Routes = [
  {
    path: '',
    component: ManagementLayoutComponent,
    loadChildren: () => import('./management/management.module').then(mod => mod.ManagementModule)
  }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  //initialNavigation: false
};


@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule.forRoot(routes, routerOptions)

  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
