import { Component } from '@angular/core';
import { UserInformationService } from './management/core/services/user-information.service';
import { LocalStorageService } from './management/core/services/local-storage.service';
import { RedirectWebsite } from './management/core/constants/user/redirect-website';
import { LocalStorageKey } from './management/core/constants/user/local-storage-key';
import { Router } from '@angular/router';
import { IUserInformation } from './management/core/interfaces/user/user-information';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers:[UserInformationService, LocalStorageService]
})
export class AppComponent {
  title = 'app';

  constructor(private websiteInformationService: UserInformationService,
  private localStorageService:LocalStorageService, private router:Router) {
    
    var userInfo = this.localStorageService.getUserInfo();

    if (userInfo != null && userInfo.customerCode != "" && userInfo.customerCode != null && userInfo.customerCode != 'null') {
      this.compareSessionWithLocalStorage(userInfo);
    }
    else {
      this.getInfoFromSession();
    }
  }

  checkIfRedirectToWebsiteWasDone(): boolean {
    var redirect=this.localStorageService.getWithExpiry(LocalStorageKey.RedirectWebsite);

    return redirect == "true";
  }

  compareSessionWithLocalStorage(locaStorageInfo: IUserInformation) {
    this.websiteInformationService.compareCustomerCode(locaStorageInfo.customerCode).subscribe(userInfo => {
      if (locaStorageInfo.customerCode == userInfo.customerCode) {
        return;
      }

      //if (userInfo.customerCode == null || userInfo.customerCode == "") {
      //  this.redirectToWebsite(userInfo.sessionTtl);
      //}

      this.localStorageService.setUserInfo(userInfo);
    });
  }

  getInfoFromSession() {
    //this.websiteInformationService.getUserInformation().subscribe(info => {
      
    //  if (info != null && info.customerCode == null && !this.checkIfRedirectToWebsiteWasDone()) {
    //    this.redirectToWebsite(info.sessionTtl);
    //    return;
    //  }

    //  this.localStorageService.setUserInfo(info);


    //});
  }

  redirectToWebsite(ttl) {
    this.localStorageService.setWithExpiry(LocalStorageKey.RedirectWebsite, "true", ttl);
    window.location.href = `${RedirectWebsite.Url}/docs`;
    return;
  }
}


