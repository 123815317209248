import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ManagementLayoutComponent } from './management/management-layout/management-layout.component';
import { ManagementHeaderComponent } from './management/management-header/management-header.component';
import { MatMenuModule } from '@angular/material/menu'
import { NgAisModule } from 'angular-instantsearch';
import { MatSelectModule, MatSortModule, MatListModule, MatDividerModule, MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ManagementLayoutComponent,
    ManagementHeaderComponent,
   
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    NgAisModule.forRoot(),
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatListModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ManagementLayoutComponent,
    ManagementHeaderComponent
  ]
})
export class LayoutModule { }
