import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserInformation } from '../interfaces/user/user-information';
import { Observable } from 'rxjs';

@Injectable()
export class UserInformationService {

  private _baseUrl: string = `/api/userinfo`;

  constructor(private httpClient: HttpClient) { }

  getUserInformation(): Observable<IUserInformation> {
    return this.httpClient.get<IUserInformation>(`${this._baseUrl}/websiteInfo`);
  }

  compareCustomerCode(code:string): Observable<IUserInformation> {
    return this.httpClient.get<IUserInformation>(`${this._baseUrl}/compare/${code}`);
  }

}
